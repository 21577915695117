import { PageLayout } from "@components";
import React from "react";
import { Demo, GetWorkDone } from ".././biopharmaceutical";
import { StaticImage } from "gatsby-plugin-image";
import FeatureCard, {
	FeatureCardLayout,
} from "@components/FeatureCard/FeatureCard";
import FeatureSummary from "@components/FeatureSummary/FeatureSummary";
import { FeatureSummaryPattern } from "@icons";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

export default function BioTech() {
	const demoProps = {
		title: "Optimize industrial biotech research and development",
		subtitle:
			"Genemod offers advanced tools designed to significantly streamline your research activities.",
		srcImg: "blue-leather-boxing.png",
		disableBackgroundShapes: true,
		rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: styles.getADemoOverride,
		leftSectionOverride: styles.leftSectionOverride,
		titleStylesOverride: styles.demoTitleStylesOverride,
		subtitleStylesOverride: styles.demoSubtitleStylesOverride,
	};

	const featureCardContent = {
		title: "Industrial biotech: vital for global sustainability and net-zero targets",
		list: [
			"Utilize Genemod's solutions to streamline the development of sustainable biotech processes.\n",
			"Genemod's inventory management platform will unlock new potential in industrial biotech research.\n",
			"Adapt and scale your biotech research effortlessly with Genemod's flexible tools.\n",
		],
		image: (
			<StaticImage
				src={"../../../assets/solutions/protocol-draft-1.png"}
				alt="Easily Manage and Submit Orders"
			/>
		),
		titleStylesOverride: styles.titleStylesOverride,
	};

	const featureSummaryProps = {
		title: "Centralized management in one system",
		list: [
			"Virtual freezer setup.",
			"Consumables management.",
			"Order management.",
		],
		sublist: [
			"Create a virtual representation of your actual freezer with adjustable shelves, racks, and cryoboxes, achieving an exact match for every item and physical component.",
			"Craft detailed layouts of your laboratory spaces for efficient handling of industrial biotechnology consumables, enhancing resource efficiency and simplifying research operations.",
			"Provide full clarity and comprehensive record-keeping for each order, ensuring smooth operation and reducing the potential for any workflow interruptions.",
		],
		imageLeft: true,
		img: <FeatureSummaryPattern />,
		applyV4Styles: true,
		reverse: true,
	};

	const getWorkDoneProps = {
		title: "Comprehensive operation in one platform",
		subtitle:
			"Explore our industrial biotech laboratory information management systems, featuring Genemod's inventory, electronic notebook, sample management, AI platform, and additional functionalities.",
		skewTop: false,
		skewBottom: false,
		showSquares: false,
		showCentralizedPlatformV2: true,
		applyV4Styles:true
	};
	const footerContent = {
		headerText: "Powered by Genemod",
		subheaderText:
			"Genemod enables large institutions with multiple teams to centralize projects and experiments on one platform. Google Docs isn’t built for life science R&D - so we built a platform that is.",
	};
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "biotech" }}>
			<Margins>
				<Demo {...demoProps} />
			</Margins>
			<Margins className={styles.featureCardsMargins}>
				<FeatureCardLayout className={styles.cards}>
					<FeatureCard {...featureCardContent} orientation={"left"} />
				</FeatureCardLayout>
			</Margins>
			<FeatureSummary {...featureSummaryProps} />
			<Margins className={styles.getWorkDoneMargins}>
				<GetWorkDone {...getWorkDoneProps} />
			</Margins>
			<LogosAndG2Section />
			<Margins className={styles.institutionsMargins}>
				<JoinInstitutionsV2
					{...footerContent}
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
					useHeaderStylesV4={true}
					useSubheaderStylesV4={true}
					joinInstitutionsContainerOverride={
						styles.joinInstitutionsContainerOverride
					}
				/>
			</Margins>
		</PageLayout>
	);
}
